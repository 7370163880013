import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ThemeWrapper from "./themes/ThemeWrapper";

const App = React.lazy(() => import("./App"));

const MatomoScript = () => {
  useEffect(() => {
    const loadMatomoScript = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://analytics.swatics.eu/js/container_SGBaus0H.js";
      script.onerror = () => console.error("Failed to load Matomo script");
      document.head.appendChild(script);

      window._mtm = window._mtm || [];
      window._mtm.push({
        "mtm.startTime": new Date().getTime(),
        event: "mtm.Start",
      });
      // Add more configuration options here if needed
    };

    if (process.env.NODE_ENV === "production") {
      loadMatomoScript();
    }
  }, []);

  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeWrapper>
        <MatomoScript />
        <React.Suspense fallback={<div>Loading...</div>}>
          <App />
        </React.Suspense>
      </ThemeWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
